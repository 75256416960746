import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/Home.vue';
import ProStep1 from '@/components/pro/Step1.vue';
import ProStep2 from '@/components/pro/Step2.vue';
import ProStep3 from '@/components/pro/Step3.vue';
import ProStep4 from '@/components/pro/Step4.vue';
import ProStep5 from '@/components/pro/Step5.vue';
import ProStep6 from '@/components/pro/Step6.vue';
import ProThankYou from '@/components/pro/ThankYou.vue';
import ProVerifyEmail from '@/components/pro/VerifyEmail.vue';
import RequestStep1 from '@/components/request/Step1.vue';
import RequestVerifyEmail from '@/components/request/VerifyEmail.vue';
import DescriptionForm from '@/components/request/DescriptionForm.vue';
import LocationForm from '@/components/request/LocationForm.vue';
import Preview from '@/components/request/Preview.vue';
import UserDashboard from '@/components/request/UserDashboard.vue'; 

const routes = [
  { path: '/', component: Home },
  { path: '/pro', component: ProStep1 },
  { path: '/pro/step2', component: ProStep2 },
  { path: '/pro/step3', component: ProStep3 },
  { path: '/pro/step4', component: ProStep4 },
  { path: '/pro/step5', component: ProStep5 },
  { path: '/pro/step6', component: ProStep6 },
  { path: '/pro/thank-you', component: ProThankYou },
  { path: '/pro/verify-email/:token', component: ProVerifyEmail },
  { path: '/request', component: RequestStep1 },
  { path: '/request/verify-email/:token', component: RequestVerifyEmail },
  { path: '/request/description', component: DescriptionForm },
  { path: '/request/location', component: LocationForm },
  { path: '/request/preview', component: Preview },
  { path: '/request/dashboard', component: UserDashboard },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
