<template>
  <div>
    <h2>User Dashboard</h2>
    <div v-if="requests.length === 0">
      <p>No requests found.</p>
    </div>
    <ul>
      <li v-for="request in requests" :key="request.id">
        <strong><br/><br/>{{ request.description }}</strong>
        <p>Date: {{ request.created_at }}</p>
        <p>Status: {{ request.status }}</p>
        <button @click="unpublishRequest(request.id)">Unpublish</button>
        <button @click="deleteRequest(request.id)">Delete</button>
        <button @click="renewRequest(request.id)">Renew</button>
      </li>
    </ul>
    <button @click="refreshRequests">Refresh</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      requests: [],
    };
  },
  async created() {
    await this.fetchRequests();
  },
  methods: {
    async fetchRequests() {
      const response = await fetch('/api/request/requests', { method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('token')}),
	}); // Fetch user requests from the backend
      this.requests = await response.json();
    },
    async unpublishRequest(id) {
      await fetch(`/api/request/unpublish-request/${id}`, { method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('token')}),
});
      this.fetchRequests();
    },
    async deleteRequest(id) {
      await fetch(`/api/request/delete-request/${id}`, { method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('token')}),
     });
      this.fetchRequests();
    },
    async renewRequest(id) {
      await fetch(`/api/request/renew-request/${id}`, { method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('token')}),
 });
      this.fetchRequests();
    },
    refreshRequests() {
      this.fetchRequests();
    },
  },
};
</script>
