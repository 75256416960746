<template>
  <div>
    <h2>Step 5: Zip Codes or Draw on Map</h2>
    <input v-model="zipCodes" placeholder="Enter zip codes (comma-separated)" />
<!--    <div id="map" style="height: 400px;"></div> -->
    <button @click="submitZipCodes">Next</button>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  </div>
</template>

<script>
//import L from 'leaflet';

export default {
  data() {
    return {
      zipCodes: '',
      errorMessage: '',
    };
  },
  mounted() {
/*
    const map = L.map('map').setView([51.505, -0.09], 13);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors'
    }).addTo(map);

    const drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);

    const drawControl = new L.Control.Draw({
      edit: { featureGroup: drawnItems }
    });
    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, (e) => {
      const layer = e.layer;
      drawnItems.addLayer(layer);
    });
*/
  },
  methods: {
    submitZipCodes() {
      this.errorMessage = '';

      const zipCodeArray = this.zipCodes.split(',').map(zip => zip.trim()).filter(zip => zip);

      if (!zipCodeArray.length) {
        this.errorMessage = 'At least one zip code is required.';
        return;
      }

      this.$store.dispatch('saveStep', { token: localStorage.getItem('token'), stepData: {field: 'zip_codes', value: zipCodeArray }})
        .then(() => {
          this.$router.push('/pro/step6');
        })
        .catch(error => {
          console.error('Error saving zip codes:', error);
          this.errorMessage = 'Failed to save zip codes. Please try again later.';
        });
    }
  }
};
</script>

<style>
.error {
  color: red;
}
</style>
