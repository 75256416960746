<template>
  <div>
    <h2>Step 1: Email and Captcha</h2>
    <input v-model="email" placeholder="Enter your email" />
    <button @click="submitEmail">Send Verification Email</button>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    <p v-if="verificationSent" class="success">A verification email has been sent to {{ email }}. Please check your inbox and click the link to continue.</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      errorMessage: '',
      verificationSent: false,
    };
  },
  methods: {
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    },
    submitEmail() {
      this.errorMessage = '';
      this.verificationSent = false; // Reset message

      // Validate email format
      if (!this.validateEmail(this.email)) {
        this.errorMessage = 'Please enter a valid email address.';
        return;
      }

      // Send verification email
      axios.post('/api/auth/send-verification-request', { email: this.email })
        .then(() => {
          this.verificationSent = true;
          this.$store.commit('setEmail', this.email);
        })
        .catch(error => {
          console.error('Error sending verification email:', error);
          this.errorMessage = 'Failed to send verification email. Please try again later.';
        });
    }
  }
};
</script>

<style>
.error {
  color: red;
}
.success {
  color: green;
}
</style>
