<template>
  <div>
    <h2>Step 6: Select Services</h2>
    <input
      type="text"
      v-model="searchQuery"
      placeholder="Search for services..."
      @input="filterServices"
    />
<br/>
    <select v-model="selectedServices" multiple>
      <option v-for="service in filteredServices" :key="service" :value="service">
        {{ service }}
      </option>
    </select>
<br/>
    <button @click="submitServices">Complete</button>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      selectedServices: [],
      availableServices: [],
      filteredServices: [],
      searchQuery: '',
      errorMessage: '',
    };
  },
  mounted() {
    this.fetchServices();
  },
  methods: {
    fetchServices() {
      axios.get('/api/services')
        .then(response => {
          this.availableServices = response.data;
          this.filteredServices = response.data; // Initialize filtered services
        })
        .catch(error => {
          console.error('Error fetching services:', error);
          this.errorMessage = 'Failed to load services. Please try again later.';
        });
    },
    filterServices() {
      const query = this.searchQuery.toLowerCase();
      this.filteredServices = this.availableServices.filter(service =>
        service.toLowerCase().includes(query)
      );
    },
    submitServices() {
      this.errorMessage = '';

      if (this.selectedServices.length === 0) {
        this.errorMessage = 'At least one service must be selected.';
        return;
      }

      this.$store.dispatch('saveStep', { token: localStorage.getItem('token'), stepData: {field: 'Services', value: this.selectedServices }})
        .then(() => {
          this.$router.push('/pro/thank-you');
        })
        .catch(error => {
          console.error('Error saving services:', error);
          this.errorMessage = 'Failed to save services. Please try again later.';
        });
    }
  }
};
</script>

<style>
.error {
  color: red;
}
</style>


