<template>
  <div>
    <h2>Thank You for Signing Up!</h2>
<p>You have successfully completed the sign-up process. You can return to any previous steps to make changes or unsubscribe if you'd like.</p>
    
    <button @click="$router.push('/pro/step5')">Update Zip Codes</button>
    <button @click="$router.push('/pro/step6')">Update Services</button>
    <button @click="unsubscribe">Unsubscribe</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  methods: {
    unsubscribe() {
      axios.post('/api/form/unsubscribe', { token: localStorage.getItem('token') })
        .then(() => {
          alert('You have been unsubscribed and your profile has been deleted.');
          this.$router.push('/');
        });
    }
  }
};
</script>