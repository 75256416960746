<template>
  <div>
    <h2>Enter Description</h2>
    <textarea v-model="description" placeholder="Description"></textarea>
    <button @click="nextStep">Next</button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      description: '',
    };
  },
  methods: {
    ...mapMutations(['setDescription']),
    nextStep() {
      this.setDescription(this.description);
      this.$router.push('/request/location');
    },
  },
};
</script>
