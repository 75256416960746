<template>
  <div>
    <h2>Step 3: Last Name</h2>
    <input v-model="lastName" placeholder="Enter your last name" />
    <button @click="submitLastName">Next</button>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lastName: '',
      errorMessage: '',
    };
  },
  methods: {
    submitLastName() {
      this.errorMessage = '';

      // Validate last name
      if (!this.lastName.trim()) {
        this.errorMessage = 'Last name is required.';
        return;
      }

      this.$store.dispatch('saveStep', { token: localStorage.getItem('token'), stepData: {field: 'last_name', value: this.lastName }})
        .then(() => {
          this.$router.push('/pro/step4');
        })
        .catch(error => {
          console.error('Error saving last name:', error);
          this.errorMessage = 'Failed to save last name. Please try again later.';
        });
    }
  }
};
</script>

<style>
.error {
  color: red;
}
</style>
