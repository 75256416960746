<template>
  <div>
    <h2>Preview Request</h2>
    <p><strong>Email:</strong> {{ email }}</p>
    <p><strong>Description:</strong> {{ description }}</p>
    <p><strong>Location:</strong> {{ city }}, {{ state }}, {{ country }}, {{ zip_code }}</p>
    <button @click="publishRequest">Publish</button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['email', 'description', 'country', 'state', 'city', 'zip_code']),
  },
  methods: {
    async publishRequest() {

	console.log(this.state);
      // Call API to publish the request
      await fetch('/api/request/publish-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('token'), data: { description: this.description, country: this.country, state: this.state, city: this.city, zip_code: this.zip_code}}),
      });

      this.$router.push('/request/dashboard');

    },
  },
};
</script>
