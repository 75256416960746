<template>
  <div>
    <h2>Step 2: First Name</h2>
    <input v-model="firstName" placeholder="Enter your first name" />
    <button @click="submitFirstName">Next</button>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstName: '',
      errorMessage: '',
    };
  },
  methods: {
    submitFirstName() {
      this.errorMessage = '';

      // Validate first name
      if (!this.firstName.trim()) {
        this.errorMessage = 'First name is required.';
        return;
      }

      this.$store.dispatch('saveStep', { token: localStorage.getItem('token'), stepData: { field: 'first_name', value: this.firstName }})
        .then(() => {
          this.$router.push('/pro/step3');
        })
        .catch(error => {
          console.error('Error saving first name:', error);
          this.errorMessage = 'Failed to save first name. Please try again later.';
        });
    }
  }
};
</script>

<style>
.error {
  color: red;
}
</style>
