<template>
<a href="/request">I NEED A PRO</a><br><br>
<a href="/pro">I AM A PRO</a><br>
<p v-if="homeMessage">{{ homeMessage }}</p>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      homeMessage: '',
    };
  },
  mounted() {
    this.fetchMessage();
  },
  methods: {
    fetchMessage() {
      axios.get('/api/services/home')
        .then(response => {
          this.homeMessage = response.data;
        })
        .catch(error => {
          console.error('Error fetching services:', error);
        });
    },
  }
};
</script>

