<template>
  <div>
    <h2>Email Verification</h2>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    <p v-if="verificationSuccess" class="success">Email verified successfully! Redirecting...</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      errorMessage: '',
      verificationSuccess: false,
    };
  },
  mounted() {
    const token = this.$route.params.token;

    // Verify the token
    axios.post('/api/auth/verify-email', { token })
      .then(() => {
        this.verificationSuccess = true;
	localStorage.setItem('token', token);
        setTimeout(() => {
          this.$router.push('/pro/step2'); // Redirect to Step 2 after 2 seconds
        }, 2000);
      })
      .catch(error => {
        console.error('Error verifying email:', error);
        this.errorMessage = 'Email verification failed. Please try again.';
      });
  }
};
</script>

<style>
.error {
  color: red;
}
.success {
  color: green;
}
</style>
