<template>
  <div>
    <h2>Step 4: Company Name</h2>
    <input v-model="companyName" placeholder="Enter your company name (if applicable)" />
    <button @click="submitCompanyName">Next</button>
  </div>
</template>

<script>
export default {
  data() {
    return { companyName: '' };
  },
  methods: {
    submitCompanyName() {
      this.$store.dispatch('saveStep', { token: localStorage.getItem('token'), stepData: {field: 'company_name', value: this.companyName }}).then(() => {
        this.$router.push('/pro/step5');
      });
    }
  }
};
</script>
