<template>
  <div>
    <h2>Enter Location</h2>
    <input v-model="country" placeholder="Country" />
    <input v-model="state1" placeholder="State/Province" />
    <input v-model="city" placeholder="City" />
    <input v-model="zip_code" placeholder="Zip Code" />
    <button @click="nextStep">Next</button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      country: '',
      state1: '',
      city: '',
      zip_code: '',
    };
  },
  methods: {
    ...mapMutations(['setLocation']),
    nextStep() {
      this.setLocation({
        country: this.country,
        state1: this.state1,
        city: this.city,
        zip_code: this.zip_code,
      });
      this.$router.push('/request/preview');
    },
  },
};
</script>
