import { createStore } from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
  state: {
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    zipCodes: [],
    services: [],
    description: '',
    title: '',
    country: '',
    state: '',
    city: '',
    zip_code: '',
  },
  mutations: {
    setEmail(state, email) {
      state.email = email;
    },
    setFirstName(state, firstName) {
      state.firstName = firstName;
    },
    setLastName(state, lastName) {
      state.lastName = lastName;
    },
    setCompanyName(state, companyName) {
      state.companyName = companyName;
    },
    setZipCodes(state, zipCodes) {
      state.zipCodes = zipCodes;
    },
    setServices(state, services) {
      state.services = services;
    },
    setDescription(state, description) {
      state.description = description;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setLocation(state, { country, state1, city, zip_code }) {
      state.country = country;
      state.state = state1;
      state.city = city;
      state.zip_code = zip_code;
    },
  },
  actions: {
    saveStep({ commit }, stepData) {
      return axios.post('/api/form/save-step', stepData).then(() => {
        commit(`set${stepData.field}`, stepData.value);
      });
    },
  },
  plugins: [createPersistedState()],
});

export default store;
